const sendEventToGTM = (eventData) => {
    if (window.dataLayer) {
        window.dataLayer.push(eventData);
    }
};



const sendEventToBing = (eventData) => {
    if (window.uetq) {
      try {

        const { event_action, event_category, event_label, cd, revenue_value, currency, ...validEventData } = eventData;
  
        const eventPayload = {
        //   ...validEventData,
        revenue_value: revenue_value || 0,
          currency: currency || 'USD',
          event_category,
          event_action,
          event_label
        };
  
        // Push the event to UET
        window.uetq.push('event', event_action, eventPayload);
        console.log('Bing UET event sent:', eventData);
      } catch (error) {
        console.error('Error sending Bing UET event:', error);
      }
    } else {
      console.error('Bing UET is not initialized or is missing.');
    }
  };
  




export const googleAnalyticsFunction = (data, courseData) => {

    console.log(`original data given for UET tag 1 - data - `, data);
    // console.log(`original data given for UET tag 2 - courseData - `,courseData);

    sendEventToGTM({
        event: 'Initiate Checkout',
        eventCategory: 'Course Purchase Triggered',
        eventAction: 'Popup Opened',
        eventLabel: 'Initiate Checkout',
        course: courseData,
        totalprice: data?.finalcost == 0 ? courseData[0].discountprice : data?.finalcost
    });
    console.log('tag manager 123', courseData)
    sendEventToBing({
        event: 'Initiate Checkout',
        event_category: 'Course Purchase Triggered',
        event_action: 'click_Register_Now',
        event_label: 'Initiate Checkout',
        data: JSON.stringify(courseData) || {},
        revenue_value: data?.discountprice
    })
};

export const googleTagForGetInTouch = (data) => {
    try {
        if (data) {
            sendEventToGTM({
                event: 'Enquiry',
                eventCategory: 'Contact Us Triggered',
                eventAction: 'Submit Button Clicked',
                eventLabel: 'Contact Us',
                data: data || {}
            });

            sendEventToBing({
                event: 'Enquiry',
                event_category: `Contact Us Triggered - ${JSON.stringify({ data })}`,
                event_action: 'Submit Button Clickedsss',
                event_label: 'Contact Ussss',
                cd: JSON.stringify({ data }) || {},
            })
        }
        else {
            return
        }
    } catch (error) {
        console.log(`error in googleTagForGetInTouch - `, error)
    }
}

export const googleTagForCompleteRegistration = (data) => {
    try {
        if (data) {
            sendEventToGTM({
                event: 'Checkout details entry',
                eventCategory: 'Next Button Triggered',
                eventAction: 'Next Button Clicked',
                eventLabel: 'Registration Form - New User',
                data: data || {}
            });

            sendEventToBing({
                event: 'Checkout details entry',
                event_category: 'Next Button Triggered',
                event_action: 'Next Button Clicked',
                event_label: 'Registration Form - New User',
                cd: JSON.stringify({ data }) || {},

            })
        }
        else {
            return
        }
    } catch (error) {
        console.log(`error in googleTagForCompleteRegistration - `, error)
    }
}

export const googleTagForNewUserCardDetails = (data) => { // 💳
    console.log('data after register', data)
    try {
        if (data) {
            sendEventToGTM({
                event: 'Purchase',
                eventCategory: 'Submit Button Triggered',
                eventAction: 'Submit Button Clicked',
                eventLabel: 'Purchase - New User',
                data: data || {}
            });

            sendEventToBing({
                event: 'Purchase',
                event_category: 'Submit Button Triggered',
                event_action: 'Submit Button Clicked',
                event_label: 'Purchase - New User',
                cd: JSON.stringify({ data }) || {},
                revenue_value: 180,
                currency: 'USD',
              });
        }
        else {
            return
        }
    } catch (error) {
        console.log(`error in googleTagForNewUserCardDetails - `, error)
    }
}

export const googleTagForExistingUserCardDetails = (data) => { // !💳
    try {
        if (data) {
            sendEventToGTM({
                event: 'Purchase',
                eventCategory: 'Submit Button Triggered',
                eventAction: 'Submit Button Clicked',
                eventLabel: 'Purchase - Existing User',
                data: data || {}
            });
        }
        else {
            return
        }
    } catch (error) {
        console.log(`error in googleTagForExistingUserCardDetails - `, error)
    }
}






// microsoft bing code  ;

export const trackPageLoadEvent = (eventName, revenue = 0, currency = "USD") => {

    console.log('micro working')
    // if (window.uetq) {

    //     window.uetq.push("event", eventName, {
    //         event_label: eventName,
    //         revenue_value: revenue,
    //         currency: currency,
    //     });
    // }
};





// Key	Description
// ea	Event Action (e.g., "InitiateCheckout", "Purchase")
// el	Event Label (a string to label the event, optional)
// ec	Event Category (a string to categorize the event, optional)
// gv	Revenue (the value of the conversion, optional)
// gc	Currency code (ISO 4217, optional, e.g., "USD")
// gclid	Google Click Identifier (optional)
// customParams	Custom parameters as an object (optional, for any additional custom key-value)



// Common UET Variables:
// event: The event name, such as "purchase", "view", "signup".

// Example: event: 'purchase'
// pid: The product ID or SKU of the item being tracked.

// Example: pid: '12345'
// em: Email address of the user (if available).

// Example: em: email@example.com
// ph: Phone number of the user (if available).

// Example: ph: '123-456-7890'
// gv: The value of the conversion (e.g., price, revenue, or total).

// Example: gv: 100.50
// gc: The currency code (e.g., 'USD', 'EUR').

// Example: gc: 'USD'
// uid: User ID (for identifying unique users).

// Example: uid: 'abc123'
// u1, u2, u3, u4, u5: Custom parameters that can be used to store extra data (e.g., categories, user types).

// Example: u1: 'premium_member'
// ec: Event Category. This is used to group events (e.g., "ecommerce", "registration").

// Example: ec: 'ecommerce'
// ea: Event Action. Describes the user action (e.g., "add_to_cart", "purchase").

// Example: ea: 'add_to_cart'
// el: Event Label. Additional descriptive information about the event (e.g., the product name).

// Example: el: 'product_name'
// cs: Campaign Source (e.g., Google, Facebook).

// Example: cs: 'Google'
// cm: Campaign Medium (e.g., CPC, email).

// Example: cm: 'email'
// cn: Campaign Name (e.g., "Holiday_Sale").

// Example: cn: 'Holiday_Sale'
// cc: Campaign Content (e.g., the specific ad or content in the campaign).

// Example: cc: 'Ad_Text'
// ck: Campaign Keyword (the keyword associated with the ad campaign).

// Example: ck: 'laptops'
// cd: Custom Data (any other custom data you want to send, could be serialized JSON).

// Example: cd: '{"customField": "value"}'
// cf: Referrer (URL of the page that referred the user to your site).

// Example: cf: 'https://referring-site.com'
// ct: Conversion Type (used for tracking specific conversions, such as "purchase", "sign_up").

// Example: ct: 'purchase'
// sb: Search Box (a search term used within your website or application).

// Example: sb: 'laptop'
// userType: You can use a custom variable to specify the type of user (e.g., 'guest', 'registered').

// Example: userType: 'guest'
// pageType: Defines the type of page on which the event occurred (e.g., 'landing', 'checkout').

// Example: pageType: 'checkout'